<template>
    <div class="PDFExport full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('system.pdfExport.path') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="pdfExport.pathId" persistent width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="showPathName"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details="auto"
                                        outlined
                                        class="is-modal"
                                        :class="{'noAction': !checkUserRights('systemPDFEdit')}"
                                        :rules="[rules.path]"
                                        id="pathmodal"
                                    ></v-text-field>
                                </template>
                                <div>
                                    <div class="dialogcontainer pa-8">
                                        <p style="font-weight: 500">{{ $t('system.pdfExport.selectPath') }}</p>
                                        <v-list>
                                            <v-list-item-group
                                                v-model="pdfExport.pathId"
                                                color="disiIrisblue"
                                                class="list mt-4 mb-8"
                                                id="pathmodal-list"
                                                mandatory
                                            >
                                                <v-list-item
                                                    v-for="(item) in this.pathlist"
                                                    :key="item.id"
                                                    :value="item.id"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                        <div class="button-container d-flex justify-space-between">
                                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                                            <v-btn elevation="0" class="content-btn small" @click="$refs.pathdialog.save(pdfExport.pathId)">{{ $t('footerButton.save') }}</v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="pdfExport-automaticReportProductTest" :disabled="!checkUserRights('systemPDFEdit')" :class="{'active': automaticReportProductTest}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-10">
                            <p>{{ $t('system.pdfExport.automaticReportProductTest') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="pdfExport-automaticReportQuickTest" :disabled="!checkUserRights('systemPDFEdit')" :class="{'active': automaticReportQuickTest}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-10">
                            <p>{{ $t('system.pdfExport.automaticReportQuickTest') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="pdfExport-automaticReportAdjCal" :disabled="!checkUserRights('systemPDFEdit')" :class="{'active': automaticReportAdjCal}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-10">
                            <p>{{ $t('system.pdfExport.automaticReportAjdCal') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="pdfExport-exportGraph" :disabled="!checkUserRights('systemPDFEdit')" :class="{'active': exportGraph}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-10">
                            <p>{{ $t('system.pdfExport.exportGraph') }}</p>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToSystem"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress" :class="{'disabled': saveDisabled || !checkUserRights('systemPDFEdit')}" @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'PDFExport',
    components: {
        SwitchOnOff,
        FooterButton,
    },
    props: {
    },
    data () {
        return{
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            pathmodal: false,
            original: {},
            rules: {
                path: v => {
                    if (this.automaticReportProductTest || this.automaticReportQuickTest || this.automaticReportAdjCal){
                        if (v){
                            return !!v;
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'pdfExport',
            'pathlist',
        ]),
        automaticReportProductTest(){
            return this.$store.state.pdfExport.automaticReportProductTest;
        },
        automaticReportQuickTest(){
            return this.$store.state.pdfExport.automaticReportQuickTest;
        },
        automaticReportAdjCal(){
            return this.$store.state.pdfExport.automaticReportAdjCal;
        },
        exportGraph(){
            return this.$store.state.pdfExport.exportGraph;
        },
        showPathName(){
            let path = this.pathlist.find(element => {
                return element.id == this.pdfExport.pathId;
            });
            if (path){
                return path.name;
            } else {
                return this.$t('general.unknown');
            }
        }
    },
    methods:{
        goToSystem(){
            this.$router.push('system');
        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.showSaveProgress = true;
                let PDFExport = {}
                if (this.pdfExport.pathId != this.original.pathId){
                    PDFExport.pathId = parseInt(this.pdfExport.pathId);
                }
                if (this.pdfExport.automaticReportProductTest != this.original.automaticReportProductTest){
                    PDFExport.automaticReportProductTest = this.pdfExport.automaticReportProductTest;
                }
                if (this.pdfExport.automaticReportQuickTest != this.original.automaticReportQuickTest){
                    PDFExport.automaticReportQuickTest = this.pdfExport.automaticReportQuickTest;
                }
                if (this.pdfExport.automaticReportAdjCal != this.original.automaticReportAdjCal){
                    PDFExport.automaticReportAdjCal = this.pdfExport.automaticReportAdjCal;
                }
                if (this.pdfExport.exportGraph != this.original.exportGraph){
                    PDFExport.exportGraph = this.pdfExport.exportGraph;
                }
                if (Object.keys(PDFExport).length === 0 && PDFExport.constructor === Object){
                    this.showSaveProgress = false;
                    this.showSaveSuccess = true;
                    this.watcher();
                    setTimeout(()=>{
                        this.saveDisabled = true;
                        this.showSaveSuccess = false;
                    }, 800)
                } else {
                    this.$store.dispatch('postAxiosNoSetter', ['/admin/pdf/settings', PDFExport])
                    .then(response=>{
                        this.showSaveProgress = false;
                        if (response.status === 200){
                            this.showSaveSuccess = true;
                            this.watcher();
                            setTimeout(()=>{
                                this.saveDisabled = true;
                                this.showSaveSuccess = false;
                            }, 800)
                        }
                    })
                }
            }
        },
        watcher(){
            //copy PDFExport to original for comparison
            this.original = JSON.parse(JSON.stringify(this.pdfExport));

            let path = this.$watch('$store.state.pdfExport.pathId', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let productTest = this.$watch('$store.state.pdfExport.automaticReportProductTest', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let quickTest = this.$watch('$store.state.pdfExport.automaticReportQuickTest', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let adjCal = this.$watch('$store.state.pdfExport.automaticReportAdjCal', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let exportGraph = this.$watch('$store.state.pdfExport.exportGraph', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function(){
                path();
                productTest();
                quickTest();
                adjCal();
                exportGraph();
            }
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/share/pathlist', 'pathlist']);
        this.$store.dispatch('getAxiosSetter', ['/admin/pdf/settings', 'pdfExport'])
            .then(response=>{
                if (response.status === 200){
                    this.watcher();
                }
            });
    },
    beforeRouteLeave (to, from, next) {
        if(this.saveDisabled){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.unsavedChanges'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">

</style>